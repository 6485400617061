// Tasks to be done
// Add date cuz this shrinks
// add database to the bookings and make it realtime

import "./BookingPage.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import React, { useState } from "react";
import TimeSlot from "./Timeslot";
import awsconfig from "./aws-exports";
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
} from "@aws-amplify/ui-react";
import logo from "./assets/indoorify-logo.svg";
import { Amplify } from "aws-amplify";
import moment from "moment";

Amplify.configure(awsconfig);

function BookingPage({ futsal }) {
  console.log(futsal);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [dateOpen, setDateOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateOpen((dateOpen) => !dateOpen);
  };

  const handleTimeSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleSubmit = () => {
    console.log(
      "Selected Date:",
      selectedDate
        ? selectedDate.format("DD-MM-YYYY")
        : moment().format("DD-MM-YYYY")
    );
    console.log("Selected Time Slot:", selectedTimeSlot);
    console.log("Advance Payment:", selectedTimeSlot.length * 500);
  };

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Heading level={4}>
            Sign Up For Free!
            <br />
            Book Your Indoors Now.
          </Heading>
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Image alt="indoortime logo" src={logo} width="250px" />
          </View>
        );
      },
    },
  };

  const formFields = {
    signUp: {
      name: {
        order: 1,
      },
      email: {
        order: 2,
      },
      phone_number: {
        order: 3,
        dialCodeList: ["+94"],
        isRequired: true,
      },
      password: {
        order: 4,
      },
      confirm_password: {
        order: 5,
      },
    },
  };

  return (
    <div id="sportsPlus">
      <Authenticator
        variation="modal"
        formFields={formFields}
        components={components}
      >
        <div className="sportsPlusDescription">
          <div id="payhere-modal"></div>
          <img
            src={`https://futsalmedias25221-dev.s3.ap-south-1.amazonaws.com/${futsal?.name.replaceAll(
              " ",
              "-"
            )}.jpg`}
            alt={futsal?.name}
          ></img>
          <h1>{futsal?.name}</h1>
          <span>Per Hour: Rs. {futsal?.priceHour}</span>
        </div>

        <div id="bookingForm">
          <div className="bookingDetails">
            <div className="date">
              <div className="dateInner">
                <h4 className="textsmall">
                  {selectedDate
                    ? selectedDate.format("MMMM")
                    : moment().format("MMMM")}
                </h4>
                <h4 className="textbig">
                  {selectedDate
                    ? selectedDate.format("DD")
                    : moment().format("DD")}
                </h4>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    maxDate={dayjs("05-31-2025")}
                    open={dateOpen}
                    format="DD-MM-YYYY"
                    views={["day", "month", "year"]}
                    disablePast
                    onChange={handleDateChange}
                    width={100}
                  />
                </LocalizationProvider>
                <button onClick={() => setDateOpen((dateOpen) => !dateOpen)}>
                  SELECT DATE
                </button>
              </div>
            </div>
            <div className="time">
              <TimeSlot onTimeSelect={handleTimeSelect} />
            </div>
          </div>
          <div className="advanceCTA">
            <label>
              Advance for Booking:{" "}
              <span>Rs. {selectedTimeSlot.length * 500}</span>
            </label>
            <button onClick={handleSubmit}>BOOK NOW</button>
          </div>
        </div>

        <div className="otherDetails">
          <div className="note">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1886 6.65985C16.4412 6.65985 16.6834 6.76018 16.862 6.93877C17.0405 7.11735 17.1409 7.35957 17.1409 7.61212V19.0394C17.1409 19.292 17.0405 19.5342 16.862 19.7128C16.6834 19.8913 16.4412 19.9917 16.1886 19.9917C15.936 19.9917 15.6938 19.8913 15.5152 19.7128C15.3367 19.5342 15.2363 19.292 15.2363 19.0394V7.61212C15.2363 7.35957 15.3367 7.11735 15.5152 6.93877C15.6938 6.76018 15.936 6.65985 16.1886 6.65985Z"
                fill="black"
              />
              <path
                d="M17.7758 24.4356C17.7758 24.8565 17.6086 25.2602 17.3109 25.5579C17.0133 25.8555 16.6096 26.0227 16.1887 26.0227C15.7678 26.0227 15.3641 25.8555 15.0664 25.5579C14.7688 25.2602 14.6016 24.8565 14.6016 24.4356C14.6016 24.0147 14.7688 23.611 15.0664 23.3133C15.3641 23.0157 15.7678 22.8485 16.1887 22.8485C16.6096 22.8485 17.0133 23.0157 17.3109 23.3133C17.6086 23.611 17.7758 24.0147 17.7758 24.4356Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8712 31.1015C23.9351 31.1015 30.4727 24.5638 30.4727 16.5C30.4727 8.43615 23.9351 1.89848 15.8712 1.89848C7.80737 1.89848 1.2697 8.43615 1.2697 16.5C1.2697 24.5638 7.80737 31.1015 15.8712 31.1015ZM15.8712 32.3712C24.6372 32.3712 31.7424 25.266 31.7424 16.5C31.7424 7.73401 24.6372 0.628784 15.8712 0.628784C7.10523 0.628784 0 7.73401 0 16.5C0 25.266 7.10523 32.3712 15.8712 32.3712Z"
                fill="black"
              />
            </svg>
            <p>
              <b>Pay the balance for the futsal on the day
              of play</b>
              <br/>
              This is only the advance payment to secure the relevant time to play 
            </p>
          </div>
          <div className="location">
            <span>Location:</span>
            <iframe src={futsal?.embedLink} title={futsal?.name}></iframe>
          </div>
        </div>
      </Authenticator>
    </div>
  );
}

export default BookingPage;
