import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Profile from './Profile';
import Football from './Football';
import Nav from './Nav';
import SportsPlus from './BookingPage';
import HamMenu from './HamMenu';
import { generateClient } from 'aws-amplify/api';
import * as queries from './graphql/queries';
import NotFound from './NotFound';
import BookingPage from './BookingPage';

function App() {
  const [allFutsals, setAllFutsals] = useState([]);

  useEffect(() => {
    async function fetchFutsals() {
      try {
        const client = generateClient( );
        const futsalsQL = await client.graphql({ query: queries.listFutsals, authMode: "userPool" });
        const futsals = futsalsQL.data.listFutsals.items;
        setAllFutsals(futsals);
      } catch (error) {
        console.error("Error fetching futsals:", error);
      }
    }

    fetchFutsals();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Profile' element={<Profile />} />
        <Route path='/Football' element={<Football />} />
        {allFutsals.map((futsal, index) => (
          <Route 
            key={index}
            path={`/Football/${futsal.name.replaceAll(' ', '-')}`} 
            element={<SportsPlus futsal={futsal} />} 
          />
        ))}
        <Route path='*' element={<NotFound/>}/>
        <Route path='/Football/Indoor-World' element={<BookingPage/>}/>
      </Routes>
      <Nav />
      <HamMenu />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
